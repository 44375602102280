.button {
  --height: 48px;
  --icon-size: 32px;
  --min-height: var(--height);
  --horizontal-padding: 24px;
  --vertical-padding: 6px;
  --gap: 8px;
  --color: var(--white);
  --background-color: var(--primary-500);
  --border-radius: 8px;

  position: relative;

  display: inline-block;
  overflow: hidden;
  height: auto;
  min-height: var(--min-height);
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);

  text-decoration: none;

  border-radius: var(--border-radius);
  outline: none;
  cursor: pointer;
  user-select: none;
  transition-duration: 450ms;
  transition-property: color;
}
.offers-cards__card .button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button--disabled {
  --background-color: lightgrey !important;

  cursor: default;
}
.button--loading {
  --background-color: var(--primary-500) !important;

  cursor: default;
}

@media (--mobile) {
  .button {
    --horizontal-padding: 16px;
    --gap: 4px;
  }
}

/** Themes **/

.button--theme-primary {
  --background-color: var(--primary-500);
  --color: var(--white);
}
.button--theme-primary.button--disabled {
  --background-color: rgba(var(--primary-500-rgb), 0.3) !important;
  --color: var(--white) !important;
}

.button--theme-primary-light {
  --background-color: var(--white);
  --color: var(--primary-500);
}

.button--theme-light-grey {
  --background-color: #eff1f5;
  --color: var(--black);
}

.button--theme-white {
  --background-color: var(--white);
  --color: var(--black);
}

.button--theme-black {
  --background: linear-gradient(
    92deg,
    rgba(var(--white-rgb), 0.07) 13.78%,
    rgba(var(--white-rgb), 0.04) 78.52%
  );
  --color: var(--white);
  border: 0.5px solid rgba(var(--white-rgb), 0.141);
}

.button--theme-transparent {
  --color: var(--white);
  --background-color: rgb(255 255 255 / 25%);

  backdrop-filter: blur(5px);
}

.button--theme-black-border {
  --background-color: transparent;
  --color: var(--black);

  border: 1px solid var(--black);
}

.button--theme-transparent-primary {
  --color: var(--primary-500);
  --background-color: rgb(255 255 255 / 25%);
  --horizontal-padding: 0;

  backdrop-filter: blur(5px);
}

.button--theme-blue {
  --background-color: #5e95ff;
  --color: var(--white);
}

.button--theme-green {
  --background-color: var(--green);
  --color: var(--black);
}

.button--theme-primary-black {
  --background-color: var(--primary-500);
  --color: var(--black);
}

.button--theme-full-black {
  --background-color: var(--black);
  --color: var(--white);
}

.button__spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: 200ms opacity;
}
.button--loading .button__spinner {
  opacity: 1;
}

/** Height **/

.button--height-xsmall {
  --height: 38px;
  --horizontal-padding: 16px;
}

.button--height-small {
  --height: 42px;
}

/** Hover state **/

.button--theme-primary:hover {
  --background-color: var(--primary-400);
  --color: var(--white);
}

.button--theme-primary-light:hover {
  --background-color: var(--white);
  --color: var(--primary-300);
}

.button--theme-white:hover {
  --background: rgba(var(--white-rgb), 0.9);
  --color: var(--black);
}

.button--theme-black:hover {
  --background: linear-gradient(
    92deg,
    rgba(var(--white-rgb), 0.07) 15%,
    rgba(var(--white-rgb), 0.04) 80%
  );
  --color: var(--white);
  border: 0.5px solid rgba(var(--white-rgb), 0.3);
}

.button--theme-transparent:hover {
  --color: var(--white);
  --background-color: rgba(var(--white-rgb), 0.3);
}

.button--theme-blue:hover {
  --background-color: rgba(94, 149, 255, 0.8);
}

.button--theme-green:hover {
  --background-color: rgba(var(--green-rgb), 0.8);
}

.button--theme-primary-black:hover {
  --background-color: rgba(var(--primary-500-rgb), 0.8);
}

.button--theme-full-black:hover {
  --background-color: rgba(var(--black-rgb), 0.8);
}

/** Active state **/

.button--theme-primary:active {
  --background-color: var(--primary-500);
  --color: var(--white);
}

.button--theme-black:active {
  --background-color: var(--black);
  --color: var(--white);
}

.button--theme-transparent:active {
  --color: var(--white);
  --background-color: rgba(var(--white-rgb), 0.4);
}

/** Background **/

.button__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: var(--background);

  background-color: var(--background-color);
  transition-duration: 200ms;
  transition-property: opacity, background-color;
}

.button--theme-black:not(.button--disabled) {
  --background-color: var(--black);
}

.button--theme-glass-white {
  border: 1px solid rgba(var(--white-rgb), 0.3);
}
.button--theme-glass-white .button__background {
  --idle-opacity: 0.8;

  background: rgba(var(--white-rgb), 0.1);
  opacity: var(--idle-opacity);
  backdrop-filter: blur(17px);
}
.button--theme-glass-white:hover .button__background {
  opacity: 1;
}
.button--theme-glass-white:active .button__background {
  opacity: var(--idle-opacity);
}

/** Content **/

.button__content {
  display: inline-flex;
  align-items: center;
  height: auto;
  min-height: var(--min-height);
}

.button--width-full .button__content {
  width: 100%;
}

.button__text {
  position: relative;
  z-index: 1;

  flex: 1;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;

  color: var(--color);
  text-decoration: none;

  text-align: center;
}
@media (--mobile) {
  .button__text {
    font-size: 15px;
  }
}

.button--loading .button__text {
  opacity: 0;
  transform: translateY(24px);
}
.offers-cards__card .button__text {
  font-size: 14px;
}

.button--impacting .button__text {
  font-weight: 700;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.button__text strong {
  font-weight: 800;
}

.button__icon {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--icon-size);
  height: var(--icon-size);

  transition-duration: 200ms;
  transition-property: all;
}

* + .button__icon {
  margin-left: var(--gap);
}

.button--theme-white.button--disabled .button__icon {
  filter: grayscale(1);

  transition-duration: 200ms;
  transition-property: all;
}

.button--loading .button__icon {
  opacity: 0;
}

.button__icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
