main {
  position: relative;
}

.layout {
  min-height: calc(100vh - var(--footer-height));
}

.layout--theme-light {
  background-color: white;
}

.layout--theme-black {
  background-color: black;
}

* + .layout__footer {
  margin-top: var(--section-gap);
}
.group + .layout__footer,
.footer + .layout__footer,
.countdown-hero + .layout__footer,
.countdown-footer + .layout__footer,
.landing-footer + .layout__footer {
  margin-top: 0;
}
