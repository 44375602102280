.footer {
  --gap: 32px;
  position: relative;

  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer__content {
  --vertical-padding: var(--spacing-56);

  display: flex;
  justify-content: space-around;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}

.footer__content__column__title {
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
}

@media (--tablet) {
  .footer__content {
    --vertical-padding: 32px;

    display: block;
    text-align: center;
  }

  .footer__content__column__links button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer__hr {
  width: 100%;
  margin: 0 auto;

  border: none;
  border-top: 1px solid var(--grey-600);
}

.footer__content__column {
  max-width: 28%;

  color: var(--light);
}

.footer__content__column__socials {
  display: flex;
  gap: 8px;
}

* + .footer__content__column__socials {
  margin-top: calc(var(--gap) / 2);
}

.footer__content__column__socials__image {
  width: 24px;
  height: 24px;
}

.footer__content__column__socials__image img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

@media (--tablet) {
  .footer__content__column__socials {
    justify-content: center;
    margin-top: 12px;
  }
}

@media (--tablet) {
  .footer__content__column {
    max-width: 100%;
  }
}

@media (--mobile) {
  .footer__content__column {
    max-width: 100%;
  }
}

@media (--tablet) {
  * + .footer__content__column {
    max-width: 100%;
    margin-top: 40px;
  }
}

.footer__content__column h2 {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: var(--white);
  text-transform: uppercase;
  cursor: default;
}

.footer__content__column__sub-text {
  font-size: 14px;

  font-weight: 300;
  line-height: 120%;
  color: rgba(var(--white-rgb), 0.8);
}

.footer__content__column__logo {
  display: block;
}

.footer__content__column__logo img {
  width: 72px;
  height: auto;
}

@media (--mobile) {
  .footer__content__column__logo img {
    width: 64px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
}

.footer__content__column__links {
  list-style: none;
}

.footer__content__column__links li {
  display: block;
}

.footer__content__column__links li button {
  text-align: left;
}

.footer__content__column__links li a,
.footer__content__column__links li button {
  display: block;
  padding-top: 8px;

  font-family: var(--font-family-text);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;

  color: rgba(var(--white-rgb), 0.8);
  text-decoration: none;
}

.footer__content__column__links li a:hover,
.footer__content__column__links li button:hover {
  text-decoration: underline;
}

* + .footer__content__column__sub-text {
  margin-top: calc(var(--gap) / 2);
}

.footer__legal {
  --vertical-padding: 40px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}

.footer__legal p {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
}

@media (--tablet) {
  .footer__legal p {
    text-align: center;
  }
}

@media (--mobile) {
  .footer__legal p {
    max-width: 40ch;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (--tablet) {
  .footer__content__column__links li button {
    margin: 0 auto;
  }
}

.footer__legal p a {
  color: var(--black);
}

.footer__content__button h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 100%; /* 20px */
  letter-spacing: 0.02em;
  color: var(--white);
}

@media (--tablet) {
  .footer__content__button h3 {
    margin-top: 64px;
  }
}

.footer__content__button__cta {
  margin-top: 0;
}

@media (--mobile) {
  .footer__content__button__cta {
    margin-top: 24px;
  }
}

.footer__legalnotice {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.footer__legalnotice p {
  font-size: 12px;
  font-weight: 400;
  line-height: 100%; /* 12px */
  letter-spacing: 0.02em;
  color: var(--white);
}

.footer__legalnotice p a {
  color: var(--white);
  text-decoration: none;
}
.footer__legalnotice p a:hover {
  text-decoration: underline;
}
