.group {
  position: relative;

  overflow: hidden;

  background-color: var(--white);
}
.layout__body > .group:first-of-type:not(.group--override-header) {
  margin-top: var(--header-height);
}
.group--override-header {
  padding-top: calc(var(--header-height));
}
.group--theme-black {
  background-color: var(--black);
}
.group--theme-white {
  background-color: var(--white);
}
.group--theme-grey-100 {
  background-color: var(--grey-100);
}
.group--theme-grey-200 {
  background-color: var(--grey-200);
}
.group--theme-pink {
  background-color: var(--pink-200);
}

.group__flare-top {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 0;

  background: radial-gradient(
      30% 50% at 50% 0%,
      rgba(var(--primary-500-rgb), 0.19) 0%,
      rgba(var(--primary-500-rgb), 0) 100%
    ),
    var(--dark);

  pointer-events: none;
  user-select: none;
}

.group__flare-bottom {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 0;

  background: radial-gradient(
    50% 85% at 50% 100%,
    rgba(var(--primary-500-rgb), 0.3) 0%,
    rgba(var(--primary-500-rgb), 0) 100%
  );

  pointer-events: none;
  user-select: none;
}

.group__flare-middle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 0;

  background: radial-gradient(
    50% 50% at 50% 40%,
    rgba(var(--primary-500-rgb), 0.3) 0%,
    rgba(var(--primary-500-rgb), 0) 100%
  );

  pointer-events: none;
  user-select: none;
}

.group__flare-middle-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;

  background: radial-gradient(
    100% 90% at 0% 50%,
    rgba(var(--primary-500-rgb), 0.35) 0%,
    rgba(var(--primary-500-rgb), 0) 100%
  );

  pointer-events: none;
  user-select: none;
}

.group__flare-middle-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 0;

  background: radial-gradient(
    30% 55% at 50% 50%,
    rgba(var(--primary-500-rgb), 0.3) 0%,
    rgba(var(--primary-500-rgb), 0) 100%
  );

  transform: translate(50%, -10%);

  pointer-events: none;
  user-select: none;
}

.group__flare-top-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 0;

  background: linear-gradient(
      256deg,
      rgba(0, 0, 0, 0) 45.83%,
      rgba(0, 0, 0, 0.4) 114.2%
    ),
    linear-gradient(153deg, rgba(0, 0, 0, 0) 31.51%, #000 80.19%),
    linear-gradient(0deg, rgb(74, 17, 15, 0.4) 0%, rgb(74, 17, 15) 100%);

  pointer-events: none;
  user-select: none;
}

* + .group {
  margin-top: var(--section-gap);
}
.group + .group {
  margin-top: 0;
}
@media (--mobile) {
  * + .group {
    margin-top: 32px;
  }
}

.group.group--border-radius-top {
  --border-radius: 85px;

  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
@media (--tablet) {
  .group.group--border-radius-top {
    --border-radius: 46px;
  }
}

.group.group--border-radius-bottom {
  --border-radius: 85px;

  padding-bottom: 72px;

  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

@media (--tablet) {
  .group.group--border-radius-bottom {
    --border-radius: 46px;

    padding-bottom: 48px;
  }
}

.group__background-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;
}
.group__background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 30%;
}

.group__body {
  position: relative;
}

* + .group__footer {
  margin-top: var(--section-gap);
}
.hero + .group__footer,
.countdown-hero + .group__footer,
.hero-home + .group__footer,
.hero-about + .group__footer,
.hero-formation + .group__footer,
.horizontal-line + .group__footer {
  margin-top: 0;
}
